import cpu from "../../../assets/svg-icons/blueCPU.svg";
import ram from "../../../assets/svg-icons/ram.svg";
import hdd from "../../../assets/svg-icons/disk.svg";
import { useState, useEffect, useContext } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import Input from "../../../components/input";
import Select from "../../../components/select";
import ubuntu from "../../../assets/svg-icons/Ubuntu logo.svg";
import debian from "../../../assets/svg-icons/debian logo.svg";
import rocky from "../../../assets/svg-icons/rocky logo.svg";
import centOS from "../../../assets/svg-icons/centOS logo.svg";
import openSUSE from "../../../assets/svg-icons/openSUSE logo.svg";
import fedora from "../../../assets/svg-icons/fedora logo.svg";
import windows from "../../../assets/images/windows.png";
import close from "../../../assets/svg-icons/closeBrand.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  listImages,
  listKeypairs,
  createKeypair,
  createServer,
  getResourceStatus,
  createPaymentLink,
} from "../../../hooks/local/userReducer";
import { convertAmountToNumber } from "../../../hooks/constants";
import { CartContext } from "../../../components/cartContext";
import { extractDiskSize } from "../layout/resuableEffect";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../../components/spinner";

const ConfigureVPS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.user.loading);
  const { images = {} } = useSelector((state) => state.user);
  const { keypairs = {} } = useSelector((state) => state.user);
  const currency = useSelector((state) => state.user.currency);
  const { setCartNumber } = useContext(CartContext);
  const [cartItems, setCartItems] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("Monthly");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("existing");
  const [loading, setLoading] = useState(false);
  const [extraVolumeSize, setExtraVolumeSize] = useState("");

  const network = "5236d605-3714-41f8-b1c1-6247d3f15cdb";
  const flavorRef = cartItems?.[0]?.productId;
  const flavorId = cartItems?.[0]?.flavorId;
  const storage = cartItems?.[0]?.diskSize;

  const extraStorage = extractDiskSize(storage);

  const selectedProjectId = useSelector(
    (state) => state.user.selectedProject.id
  );

  useEffect(() => {
    const cart = sessionStorage.getItem("cart");
    if (cart) {
      const parsedCart = JSON.parse(cart);
      let price = "";
      if (currency === "USD") {
        price = parsedCart[0].dollarPrice;
      } else if (currency === "NGN") {
        price = parsedCart[0].nairaPrice;
      }
      setCartItems(parsedCart);
      setCartNumber(price);
    } else {
      setCartItems([]);
    }
  }, [setCartItems, setCartNumber, currency]);

  const calculateExtraVolumeCost = (size, currency) => {
    const pricePerGB = currency === "USD" ? 0.18 : 250;
    return size ? parseFloat(size) * pricePerGB : 0;
  };

  const calculateTotalPrice = (cartItems) => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      let priceString = "";
      if (currency === "USD") {
        priceString = item.dollarPrice;
      } else if (currency === "NGN") {
        priceString = item.nairaPrice;
      }
      const price = parseFloat(
        priceString
          .replace(/NGN|USD|\$/g, "")
          .replace(/,/g, "")
          .trim()
      );
      totalPrice += price;
    });
    return totalPrice;
  };

  const calculateTotalAmount = (cartItems, extraVolumeSize, currency) => {
    const totalCartPrice = calculateTotalPrice(cartItems);
    const extraVolumeCost = calculateExtraVolumeCost(extraVolumeSize, currency);
    return totalCartPrice + extraVolumeCost;
  };

  const removeFromCart = () => {
    sessionStorage.removeItem("cart");
    setCartItems([]);
    setCartNumber(0);
  };

  let totalPrice = calculateTotalPrice(cartItems).toLocaleString();

  useEffect(() => {
    dispatch(listImages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listKeypairs(selectedProjectId));
  }, [dispatch, selectedProjectId]);

  const handleSelect = (duration) => {
    setSelectedDuration(duration);
  };

  const activeStyle =
    "text-sm bg-primaryBlue/10 rounded px-5 md:px-10 h-14 flex items-center border border-primaryBlue cursor-pointer";
  const inactiveStyle =
    "text-sm bg-[#c4c4c416] rounded px-5 md:px-10 h-14 flex items-center border dark:border-[#c4c4c424] cursor-pointer";

  const formatOptions = (data = []) => {
    return data.map((item) => ({
      value: item?.id,
      label: `${item?.os_distro ? `${item?.os_distro} - ` : ""}${item?.name}`,
    }));
  };

  const formatKeypairOptions = (keypairs = []) => {
    return Array.isArray(keypairs)
      ? keypairs.map(({ keypair }) => ({
          value: keypair?.name,
          label: keypair?.name,
        }))
      : [];
  };

  // Keypair
  const keypairOptions = Array.isArray(keypairs)
    ? formatKeypairOptions(keypairs)
    : [];

  // Images
  const ubuntuOptions = formatOptions(images.ubuntu);
  const debianOptions = formatOptions(images.debian);
  const rockyOptions = formatOptions(images.rocky);
  const centosOptions = formatOptions(images.centos);
  const opensuseOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("openSUSE")) || []
  );
  const fedoraOptions = formatOptions(images["fedora-coreos"]);
  const otherUbuntuOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("Ubuntu")) || []
  );
  const windowsOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("Windows")) || []
  );

  const allUbuntuOptions = [...ubuntuOptions, ...otherUbuntuOptions];

  const getOptionsForSelectedImage = () => {
    switch (selectedImage) {
      case "ubuntu":
        return allUbuntuOptions;
      case "debian":
        return debianOptions;
      case "rocky":
        return rockyOptions;
      case "centos":
        return centosOptions;
      case "opensuse":
        return opensuseOptions;
      case "fedora":
        return fedoraOptions;
      case "windows":
        return windowsOptions;
      default:
        return [];
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setSelectedValue("");
  };

  const createNewKeypair = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Keypair Name cannot be empty"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { name } = values;

      let createKeypairData = {
        name,
      };
      const { payload } = await dispatch(
        createKeypair({ createKeypairData, projectId: selectedProjectId })
      );

      if (payload) {
        dispatch(listKeypairs(selectedProjectId));

        downloadPrivateKey(payload.keypair);

        resetForm();
      }
    },
  });

  const downloadPrivateKey = (keypair) => {
    const privateKey = keypair.private_key;
    const filename = `${keypair.name}.pem`;

    const blob = new Blob([privateKey], { type: "application/x-pem-file" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    link.click();
  };

  const handlePaymentDuration = (duration) => {
    if (!duration) return duration;
    const paymentDuration =
      duration.charAt(0).toLowerCase() + duration.slice(1);
    return paymentDuration;
  };

  const createNewServer = useFormik({
    initialValues: {
      name: "",
      imageRef: "",
      old_key_pair_name: "",
      volume_size: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Instance Name cannot be empty"),
      imageRef: Yup.string().required("Image cannot be empty"),
      old_key_pair_name: Yup.string().required("Key pair cannot be empty"),
      volume_size: Yup.string().optional(),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { name, imageRef, old_key_pair_name, volume_size } = values;

      const createServerData = {
        name,
        flavorRef,
        imageRef,
        network,
        old_key_pair_name,
        flavor_id: flavorId,
        volume_size,
        payment_method: handlePaymentDuration(selectedDuration),
      };
      const { payload } = await dispatch(
        createServer({ createServerData, projectId: selectedProjectId })
      );
      const serverId = payload?.server?.id;

      if (
        payload.statuscode === "00" &&
        payload.statusmessage === "successfuly"
      ) {
        setLoading(true);
        const pollForActiveStatus = async (
          serverId,
          // 3mins requery
          maxRetries = 30,
          delay = 10000
        ) => {
          for (let attempt = 0; attempt < maxRetries; attempt++) {
            const { payload } = await dispatch(getResourceStatus({ serverId }));

            if (
              payload?.data?.status === "active" &&
              payload?.data?.statuscode === "00"
            ) {
              setLoading(false);
              navigate("/client-area");
              removeFromCart();
              resetForm();
              return;
            }

            await new Promise((resolve) => setTimeout(resolve, delay));
          }
          setLoading(false);
        };

        await pollForActiveStatus(serverId);
      }
    },
  });

  const handleVolumeChange = (e) => {
    setExtraVolumeSize(e.target.value);
    createNewServer.setFieldValue("volume_size", e.target.value);
  };

  return (
    <div className="pb-10 min-h-screen">
      <p className="mb-4">Configure your VPS. </p>
      {cartItems.length > 0 ? (
        <>
          <form onSubmit={createNewServer.handleSubmit}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 lg:col-span-8 border dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800 rounded-md overflow-clip pb-6">
                <div className="px-4 pt-5 grid md:flex justify-between items-center text-lg crimson-font">
                  <span>{cartItems.length > 0 ? cartItems[0].name : ""}</span>
                  <span className="font-semibold text-[#1da1fc]">
                    {cartItems.length > 0
                      ? currency === "NGN"
                        ? cartItems[0].nairaPrice.replace("NGN ", "")
                        : cartItems[0].dollarPrice.replace("$ ", "")
                      : "No price available"}
                    <span className="text-sm font-normal text-gray-600">
                      {" "}
                      /month
                    </span>
                  </span>
                </div>

                <div className="p-4 border-b dark:border-b-[#c4c4c424] bg-[#c4c4c410] grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-4 text-xs mt-3">
                  <div className="flex items-center gap-2">
                    <img src={cpu} alt="" className="h-4" />
                    <span>
                      VCPUs: {cartItems.length > 0 ? cartItems[0].vcpu : ""}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={ram} alt="" className="h-4" />
                    <span>
                      RAM: {cartItems.length > 0 ? cartItems[0].ramSize : ""}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={hdd} alt="" className="h-4" />
                    <span>
                      Root disk:{" "}
                      {cartItems.length > 0 ? cartItems[0].diskSize : ""}
                    </span>
                  </div>
                </div>

                <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
                  <div className="p-4">
                    <p className="text-sm">1. Select term length</p>
                    <div className="flex items-center mt-2 gap-4">
                      <div
                        onClick={() => handleSelect("Monthly")}
                        className={
                          selectedDuration === "Monthly"
                            ? activeStyle
                            : inactiveStyle
                        }
                      >
                        <span>Monthly</span>
                      </div>
                      <div
                        onClick={() => handleSelect("Yearly")}
                        className={
                          selectedDuration === "Yearly"
                            ? activeStyle
                            : inactiveStyle
                        }
                      >
                        <span className="flex gap-3 items-center">
                          <span>Yearly</span>
                          <span className="text-[10px] bg-black text-white p-1 rounded">
                            1% discount
                          </span>
                        </span>
                      </div>
                      <p className="hidden">Selected: {selectedDuration}</p>
                    </div>
                  </div>
                </div>
                <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
                  <div className="p-4">
                    <p className="text-sm">2. Images</p>
                    <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-4">
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "ubuntu"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("ubuntu")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={ubuntu} alt="Ubuntu" className="h-10" />
                        </div>
                        <p className="text-center">Ubuntu</p>
                      </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "debian"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("debian")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={debian} alt="Debian" className="h-10" />
                        </div>
                        <p className="text-center">Debian</p>
                      </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "rocky"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("rocky")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={rocky} alt="Rocky" className="h-10" />
                        </div>
                        <p className="text-center">Rocky</p>
                      </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "centos"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("centos")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={centOS} alt="CentOS" className="h-10" />
                        </div>
                        <p className="text-center">CentOS</p>
                      </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "opensuse"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("opensuse")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={openSUSE} alt="openSUSE" className="h-10" />
                        </div>
                        <p className="text-center">openSUSE</p>
                      </div>
                      <div
                        className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                          selectedImage === "fedora"
                            ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                            : ""
                        }`}
                        onClick={() => handleImageClick("fedora")}
                      >
                        <div className="flex justify-center mb-4">
                          <img src={fedora} alt="Fedora" className="h-10" />
                        </div>
                        <p className="text-center">Fedora</p>
                      </div>
                      {parseInt(cartItems[0]?.ramSize?.replace(/\D/g, "")) >=
                        4 && (
                        <div
                          className={`dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer transition-all text-sm ${
                            selectedImage === "windows"
                              ? "border bg-primaryBlue/10 border-primaryBlue dark:border-primaryBlue dark:bg-primaryBlue/5"
                              : ""
                          }`}
                          onClick={() => handleImageClick("windows")}
                        >
                          <div className="flex justify-center mb-4">
                            <img src={windows} alt="Windows" className="h-10" />
                          </div>
                          <p className="text-center">Windows</p>
                        </div>
                      )}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mt-10">
                      {selectedImage && (
                        <div>
                          <p className="capitalize">{selectedImage}</p>
                          <Select
                            name={"imageRef"}
                            options={[
                              {
                                value: "",
                                label: "Select Image",
                                disabled: true,
                              },
                              ...getOptionsForSelectedImage(),
                            ]}
                            value={createNewServer.values.imageRef}
                            onChange={(option) => {
                              createNewServer.setFieldValue(
                                "imageRef",
                                option.target.value
                              );
                            }}
                            onError={
                              createNewServer.errors.imageRef &&
                              createNewServer.touched.imageRef
                                ? createNewServer.errors.imageRef
                                : null
                            }
                          />
                        </div>
                      )}

                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
                  <div className="p-4">
                    <p className="text-sm">3. Instance name</p>
                    <div className="mt-2 grid grid-cols-1 md:grid-cols-2">
                      <div>
                        <Input
                          label={"Name"}
                          type={"text"}
                          name={"name"}
                          value={createNewServer.values.name}
                          onBlur={createNewServer.handleBlur}
                          onChange={createNewServer.handleChange}
                          onError={
                            createNewServer.errors.name &&
                            createNewServer.touched.name
                              ? createNewServer.errors.name
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
                  <div className="p-4">
                    <p className="text-sm">4. Key-pairs</p>
                    <div className="flex divide-x text-sm mt-4">
                      <div
                        className={`cursor-pointer pe-4 ${
                          selectedOption === "existing"
                            ? "text-black dark:text-white"
                            : "text-[#c4c4c4] dark:text-[#c4c4c450]"
                        }`}
                        onClick={() => setSelectedOption("existing")}
                      >
                        Select from existing keys
                      </div>
                      <div
                        className={`cursor-pointer ps-4 ${
                          selectedOption === "new"
                            ? "text-black dark:text-white"
                            : "text-[#c4c4c4] dark:text-[#c4c4c450]"
                        }`}
                        onClick={() => setSelectedOption("new")}
                      >
                        Create new key pair
                      </div>
                    </div>

                    {/* Conditionally render the Select inputs */}
                    <div className="mt-2 grid grid-cols-1 md:grid-cols-2 items-end">
                      {selectedOption === "existing" && (
                        <div className="mt-4">
                          <Select
                            name={"old_key_pair_name"}
                            options={[
                              {
                                value: "",
                                label: "Select Keypair",
                                disabled: true,
                              },
                              ...keypairOptions,
                            ]}
                            value={createNewServer.values.old_key_pair_name}
                            onChange={(option) => {
                              createNewServer.setFieldValue(
                                "old_key_pair_name",
                                option.target.value
                              );
                            }}
                            onError={
                              createNewServer.errors.old_key_pair_name &&
                              createNewServer.touched.old_key_pair_name
                                ? createNewServer.errors.old_key_pair_name
                                : null
                            }
                          />
                        </div>
                      )}

                      {selectedOption === "new" && (
                        <div className="grid gap-4">
                          <div>
                            <div className="grid gap-4 mt-8">
                              <Input
                                label={"Name"}
                                type={"text"}
                                name={"name"}
                                value={createNewKeypair.values.name}
                                onBlur={createNewKeypair.handleBlur}
                                onChange={createNewKeypair.handleChange}
                                onError={
                                  createNewKeypair.errors.name &&
                                  createNewKeypair.touched.name
                                    ? createNewKeypair.errors.name
                                    : null
                                }
                              />
                              <div className="grid">
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    createNewKeypair.handleSubmit(e);
                                  }}
                                  className="text-sm md:text-xs opacity-70 text-white bg-[#1DA1F2] rounded px-8 py-4 cursor-pointer"
                                >
                                  Create Key
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="dark:text-white bg-white dark:bg-slate-800">
                  <div className="p-4">
                    <p className="text-sm">5. Extra storage (Optional)</p>
                    <div className="mt-2 grid grid-cols-1 md:grid-cols-2">
                      <div>
                        <Input
                          type="text"
                          placeholder={`e.g ${storage}`}
                          name={"volume_size"}
                          value={extraVolumeSize}
                          onBlur={createNewServer.handleBlur}
                          onChange={handleVolumeChange}
                          onError={
                            createNewServer.errors.volume_size &&
                            createNewServer.touched.volume_size
                              ? createNewServer.errors.volume_size
                              : null
                          }
                        />

                        {storage && (
                          <span className="text-gray-500 text-xs mt-2">
                            Extra storage must not exceed {storage}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="p-4 mt-4">
                  <button
                    className="bg-primaryBlue text-white text-sm py-5 px-8 rounded-sm"
                    type="submit"
                  >
                    Configure
                  </button>
                </div> */}
              </div>

              <div className="col-span-12 lg:col-span-4 relative">
                <div className="bg-[#1da1f2] rounded text-white md:sticky md:top-24">
                  <div className="border-b border-white/30 p-5">
                    <span className="font-bold crimson-font"> Summary </span>
                  </div>
                  <div className="p-4 text-sm grid gap-4">
                    <div className="grid grid-cols-2 gap-1">
                      <p>Items:</p>
                      <p className="font-semibold">{cartItems.length}</p>
                    </div>

                    <div className="grid grid-cols-2 gap-1">
                      <p className="">Selected dew:</p>
                      <p className="font-semibold">
                        {cartItems.length > 0 ? cartItems[0].name : ""}
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-1">
                      <p className="">Term length:</p>
                      <p className="font-semibold">{selectedDuration}</p>
                    </div>

                    {extraVolumeSize && (
                      <div className="grid grid-cols-2 gap-1">
                        <p className="">Extra volume:</p>
                        <p className="font-semibold">{extraVolumeSize} GB</p>
                      </div>
                    )}

                    {extraVolumeSize && (
                      <div className="grid grid-cols-2 gap-1">
                        <p>Extra Volume Cost:</p>
                        <p className="font-semibold">
                          {currency === "USD" ? "USD " : "₦ "}
                          {calculateExtraVolumeCost(
                            extraVolumeSize,
                            currency
                          ).toLocaleString()}
                        </p>
                      </div>
                    )}

                    <div className="grid grid-cols-2 gap-1">
                      <p>Amount:</p>
                      <p className="font-semibold">
                        {currency}{" "}
                        {selectedDuration === "Monthly"
                          ? convertAmountToNumber(totalPrice)
                          : convertAmountToNumber(totalPrice) * 12}
                      </p>
                    </div>

                    {extraVolumeSize && (
                      <div className="grid grid-cols-2 gap-1">
                        <p className="font-semibold">TOTAL AMOUNT:</p>
                        <p className="font-semibold">
                          {currency === "USD" ? "USD " : "₦ "}
                          {calculateTotalAmount(
                            cartItems,
                            extraVolumeSize,
                            currency
                          ).toLocaleString()}
                        </p>
                      </div>
                    )}

                    <div className="mt-4 grid gap-2">
                      <button
                        className="text-primaryBlue bg-white rounded p-4 text-xs w-full"
                        type="submit"
                      >
                        Provision
                      </button>

                      <div
                        onClick={removeFromCart}
                        className="text-center cursor-pointer text-xs p-4 rounded hover:bg-red-500 transition-all"
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <div className="flex justify-center items-center h-[400px]">
          <div className="text-center grid gap-2">
            <p className="text-[#1da1f2] text-xl font-medium crimson-font">
              {" "}
              You haven't selected a VPS to configure{" "}
            </p>
            <Link
              to={"/client-area/vps"}
              className="dark:text-white/50 text-black/50 text-sm underline"
            >
              {" "}
              Choose and buy a Flavour{" "}
            </Link>
          </div>
        </div>
      )}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
            <p className="ml-3">Creating server...</p>
          </Backdrop>
        </div>
      )}
    </div>
  );
};
export default ConfigureVPS;

import deleteIcon from "../../../assets/svg-icons/deleteRed.svg";
import { useEffect, useState } from "react";
import attachIcon from "../../../assets/svg-icons/attach.svg";
import detachIcon from "../../../assets/svg-icons/detach.svg";
import close from "../../../assets/svg-icons/closeBrand.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  attachedVolumeToInstance,
  createProjectVolumes,
  deleteVolumeFromInstance,
  detachedVolumeToInstance,
  listServers,
  projectVolumeList,
} from "../../../hooks/local/userReducer";
import { useVolumeList } from "../layout/resuableEffect";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../../../components/select";
import Button from "../../../components/button";
import { FormatDate } from "../../../hooks/constants";
import Input from "../../../components/input";
import Modal from "../../../components/modal";

const Volumes = () => {
  document.title = "Volumes - CloudVantage";

  const dispatch = useDispatch();
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedVolume, setSelectedVolume] = useState("");
  const [volumeId, setVolumeId] = useState();
  const [instanceModal, setInstanceModal] = useState(false);
  const [deleteVolumeInstanceModal, setDeleteVolumeInstanceModal] =
    useState(false);
  const [volumeModal, setVolumeModal] = useState(false);
  const projectId = useSelector((state) => state.user.selectedProject).id;
  const toggleInstanceModal = () => {
    setInstanceModal(!instanceModal);
  };

  const toggleVolumeModal = () => {
    setVolumeModal(!volumeModal);
  };
  const volumeSizeOptions = [
    { value: "", label: "select an option" },
    { value: "5", label: "5 GB" },
    { value: "10", label: "10 GB" },
    { value: "20", label: "20 GB" },
    { value: "50", label: "50 GB" },
  ];
  const volumePaymentOptions = [
    { value: "", label: "Select an option" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
  ];

  const volumeList = useVolumeList(projectId);
  const volumeListMap = Array.isArray(volumeList)
    ? volumeList.map((volume) => ({
        name: volume.name === "" ? "Default Volume" : volume.name,
        attachedInstance: volume.status.toUpperCase(),
        id: volume.id,
        status: volume.status,
        createdAt: FormatDate(volume.created_at),
        sizeInGB: volume.size,
        attachment: volume.attachments,
        device:
          volume.attachments && volume.attachments.length > 0
            ? (volume.attachments[0].device ?? "N/A")
            : "N/A",
        serverName:
          volume.attachments && volume.attachments.length > 0
            ? (volume.attachments[0].server_name ?? "N/A")
            : "N/A",
      }))
    : [];

  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  // Filter volumes based on search text
  const filteredVolumes = volumeListMap.filter((volume) =>
    volume.name.toLowerCase().includes(searchText)
  );

  // Calculate pagination data
  const totalPages = Math.ceil(filteredVolumes.length / entriesPerPage);
  const startIndex = (currentPage - 1) * entriesPerPage;
  const currentEntries = filteredVolumes.slice(
    startIndex,
    startIndex + entriesPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const createVolume = useFormik({
    initialValues: {
      size: "",
      payment: "",
      volumeName: "",
    },
    validationSchema: Yup.object({
      size: Yup.string().required("Size is required"),
      payment: Yup.string().required("Payment method is required"),
      volumeName: Yup.string().required("Volume name is required"),
    }),
    onSubmit: async (values) => {
      const { size, payment, volumeName } = values;
      const volumeData = {
        name: volumeName,
        size,
        payment_method: payment,
        projectId,
      };
      const { payload } = await dispatch(createProjectVolumes(volumeData));
      if (payload.statuscode === "00") {
        await dispatch(projectVolumeList(projectId));
        window.location.reload();
      }
    },
  });

  const [instanceOptions, setInstanceOptions] = useState([]);

  const fetchServers = async () => {
    try {
      const { payload } = await dispatch(listServers(projectId));
      const options = payload.map((server) => ({
        value: server.id,
        label: server.name,
      }));
      setInstanceOptions(options);
    } catch (error) {
      console.error("Error fetching servers:", error);
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchServers();
    }
  }, [projectId]);

  const attachVolumeToInstance = useFormik({
    initialValues: {
      instance: "",
    },
    validationSchema: Yup.object({
      instance: Yup.string().required("Kindly select an instance."),
    }),
    onSubmit: async (values) => {
      const { instance } = values;
      const volumeData = {
        volumeId,
        serverId: instance,
        projectId,
        device: "/dev/sdb",
      };
      const { payload } = await dispatch(attachedVolumeToInstance(volumeData));
      if (payload.statuscode === "00") {
        toggleInstanceModal();
        window.location.reload();
      }
    },
  });

  const detachVolumeFromInstance = async (instanceAttachments) => {
    const { server_id, volume_id } = instanceAttachments;
    const volumeData = { volume_id, server_id, projectId };
    const { payload } = await dispatch(detachedVolumeToInstance(volumeData));
    if (payload.statuscode === "00") {
      window.location.reload();
    }
  };

  const deleteVolumeInstance = async (volumeId) => {
    const instanceData = { volumeId, projectId };
    const { payload } = await dispatch(deleteVolumeFromInstance(instanceData));
    if (payload.statuscode === "00") {
      setDeleteVolumeInstanceModal(false);
      window.location.reload();
    }
  };

  return (
    <div className="text-sm">
      <div className="mb-6 dark:text-white">Manage Volumes</div>
      <div className="grid md:flex md:items-center md:justify-between gap-4 mb-4">
        <div className="w-full md:w-1/2 lg:w-1/3">
          <input
            type="search"
            name=""
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search Volumes..."
            className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
          />
        </div>
        <div
          className="px-4 py-3 text-xs border-[1.5px] border-primaryBlue rounded-md text-primaryBlue cursor-pointer w-fit"
          onClick={toggleVolumeModal}
        >
          Create volume
        </div>
      </div>
      <div className="overflow-x-auto rounded-md dark:text-white">
        <table className="w-full text-sm text-left overflow-hidden">
          <thead className="bg-white dark:bg-slate-800 px-3 md:px-4 border dark:border-[#c4c4c432] text-sm font-semibold">
            <tr>
              <th scope="col" className="px-6 py-4 whitespace-nowrap w-5">
                #
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap max-w-52">
                Volume name
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Volume size (GB)
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Status
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Device
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Attached instance
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-slate-800 text-black/50 dark:text-white/50 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] text-sm">
            {currentEntries.map((row, index) => (
              <tr
                key={index}
                className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
              >
                <td className="text-start py-4 px-6 w-5">
                  {(currentPage - 1) * entriesPerPage + (index + 1)}
                </td>
                <td className="text-start py-4 px-6 truncate max-w-52">
                  {row.name}
                </td>
                <td className="text-start py-4 px-6">{row.sizeInGB}</td>
                <td className="text-start py-4 px-6">{row.status}</td>
                <td className="text-start py-4 px-6">{row.device}</td>

                <td className="text-start py-4 px-6">
                  {row.attachedInstance === "AVAILABLE" ? (
                    <div
                      className="w-fit text-xs border dark:border-[#c4c4c424] rounded-md p-2 cursor-pointer flex items-center gap-1 text-primaryBlue"
                      onClick={() => {
                        toggleInstanceModal();
                        setVolumeId(row.id);
                      }}
                    >
                      <img src={attachIcon} alt="Delete" className="h-4" />
                      <span className="pt-[2px]">Attach to instance</span>
                    </div>
                  ) : (
                    <div className="">{row.serverName}</div>
                  )}
                </td>

                <td className="text-start py-4 px-6 flex items-center gap-4">
                  <div
                    onClick={() => {
                      setSelectedVolume(row);
                      setDeleteVolumeInstanceModal(true);
                    }}
                    className="w-fit truncate text-xs border dark:border-[#c4c4c424] rounded-md p-2 cursor-pointer flex items-center gap-1 text-red-500"
                  >
                    <img src={deleteIcon} alt="Delete" className="h-4" />
                    <span className="pt-[2px] truncate">Delete volume</span>
                  </div>
                  {row.attachedInstance === "IN-USE" && (
                    <div
                      onClick={() => {
                        detachVolumeFromInstance(row.attachment[0]);
                      }}
                      className="w-fit truncate text-xs border dark:border-[#c4c4c424] rounded-md p-2 cursor-pointer flex items-center gap-1 text-red-500"
                    >
                      <img src={detachIcon} alt="Delete" className="h-4" />
                      <span className="pt-[2px] truncate">
                        Detach from instance
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="grid justify-center gap-4 md:flex md:justify-between items-center mt-4 dark:text-white">
        <p className="text-gray-400 text-sm">
          Showing{" "}
          <span className="font-medium text-black dark:text-white">
            {(currentPage - 1) * entriesPerPage + 1}
          </span>{" "}
          to{" "}
          <span className="font-medium text-black dark:text-white">
            {Math.min(currentPage * entriesPerPage, filteredVolumes.length)}
          </span>{" "}
          of{" "}
          <span className="font-medium text-black dark:text-white">
            {filteredVolumes.length}
          </span>{" "}
          results
        </p>
        <div className="flex gap-3">
          <button
            className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${
              currentPage === 1
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100"
            }`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${
              currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100"
            }`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      {/* instance modal */}
      {instanceModal && (
        <div className="fixed top-0 h-screen w-full left-0 z-50 bg-black/60 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-[#141D26] rounded-md p-4 md:p-10 w-full md:w-1/3 max-h-[80%] overflow-y-scroll">
            <div className="flex items-center justify-between mb-4">
              <p className="text-primaryBlue">Attach to an instance</p>
              <span onClick={toggleInstanceModal} className="cursor-pointer">
                <img alt="" src={close} className="h-5" />
              </span>
            </div>

            <form onSubmit={attachVolumeToInstance.handleSubmit}>
              <select
                className="py-4 rounded-md border border-[#c4c4c460] focus:outline-0 bg-white dark:bg-slate-800 text-sm w-full"
                value={selectedInstance}
                onChange={(e) => {
                  setSelectedInstance(e.target.value);
                  attachVolumeToInstance.setFieldValue(
                    "instance",
                    e.target.value
                  );
                }}
                name="instance"
                onBlur={attachVolumeToInstance.handleBlur}
              >
                <option value="" disabled>
                  Select Instance
                </option>
                {instanceOptions.map((instance, index) => (
                  <option key={index} value={instance.value}>
                    {instance.label
                      .replace(/_/g, " ")
                      .replace(/^\w/, (c) => c.toUpperCase())}
                  </option>
                ))}
              </select>
              <span className="text-red-500 text-xs">
                {attachVolumeToInstance.touched.instance &&
                  attachVolumeToInstance.errors.instance}
              </span>
              <div className="mt-6">
                <button
                  type="submit"
                  className="bg-[#1DA1F2] text-white text-sm px-6 py-4 rounded-sm"
                >
                  Attach to instance
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* volume modal */}
      {volumeModal && (
        <div className="fixed top-0 h-screen w-full left-0 z-50 bg-black/60 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-[#141D26] rounded-md p-4 md:p-10 w-full md:w-1/3 max-h-[80%] overflow-y-scroll">
            <div className="flex items-center justify-between mb-4">
              <p className="text-primaryBlue">Create a vloume</p>
              <span onClick={toggleVolumeModal} className="cursor-pointer">
                <img alt="" src={close} className="h-5" />
              </span>
            </div>

            <form className="gap-y-6 grid" onSubmit={createVolume.handleSubmit}>
              <Input
                label={"Volume name"}
                type={"text"}
                name={"volumeName"}
                value={createVolume.values.volumeName}
                onBlur={createVolume.handleBlur}
                onChange={createVolume.handleChange}
                onError={
                  createVolume.errors.volumeName &&
                  createVolume.touched.volumeName
                    ? createVolume.errors.volumeName
                    : null
                }
              />
              <Select
                label="Size:"
                name="size"
                options={volumeSizeOptions}
                valueKey={"value"}
                labelKey={"label"}
                selectValue={createVolume.values.size}
                selectBlur={createVolume.handleBlur}
                onChange={(event) =>
                  createVolume.setFieldValue("size", event.target.value)
                }
                onError={
                  createVolume.touched.size && createVolume.errors.size
                    ? createVolume.errors.size
                    : null
                }
              />
              <Select
                label="Payment:"
                name="payment"
                options={volumePaymentOptions}
                valueKey={"value"}
                labelKey={"label"}
                selectValue={createVolume.values.payment}
                selectBlur={createVolume.handleBlur}
                onChange={(event) =>
                  createVolume.setFieldValue("payment", event.target.value)
                }
                onError={
                  createVolume.touched.payment && createVolume.errors.payment
                    ? createVolume.errors.payment
                    : null
                }
              />
              <Button buttonText={"Create Volume"} buttonRole={"submit"} />
            </form>
          </div>
        </div>
      )}

      {/* Delete  Volume  Modal Confirmation */}
      <Modal
        title={"Delete Volume"}
        isVisible={deleteVolumeInstanceModal}
        onClose={() => setDeleteVolumeInstanceModal(false)}
      >
        Are you sure you want to delete {selectedVolume.name}?
        <div className="flex justify-between pt-6">
          <button
            className="bg-red-500 text-white text-sm px-6 py-2 rounded-sm"
            onClick={() => {
              deleteVolumeInstance(selectedVolume.id);
            }}
          >
            Yes
          </button>
          <button
            className="bg-[#1DA1F2] text-white text-sm px-6 py-2 rounded-sm"
            onClick={() => setDeleteVolumeInstanceModal(false)}
          >
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Volumes;

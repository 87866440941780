import { showErrorMessage } from "../constants";
import {
  baseUrlNoAuth,
  baseUrlWithAuth,
  virtualAccountBaseUrl,
} from "./apiClient";

export class apiEndPoints {
  static extractError(error) {
    let extracted = [];
    if (error.isAxiosError) {
      if (error.response) {
        if (error.response.data && error.response.data.statusmessage) {
          extracted.push(error.response.data.statusmessage);
        } else {
          extracted.push("An unexpected Error occurred");
        }
      } else if (error.request) {
        extracted.push("Network Error Occurred");
      } else {
        extracted.push("An Unexpected Error Occurred");
      }
    } else {
      extracted.push(error.message || "An unexpected Error occurred");
    }
    // extracted.forEach((errorMsg) => showErrorMessage(errorMsg));
  }

  static async userRegistration(data) {
    try {
      return baseUrlNoAuth.post("/users/register", data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async userSignIn(data) {
    try {
      return baseUrlNoAuth.post("/users/login/token", data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async userSignOut() {
    try {
      return baseUrlWithAuth.post("/users/logout");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async listFlavours() {
    try {
      return baseUrlWithAuth.get("/openstack/flavors");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async listUserProjects() {
    try {
      return baseUrlWithAuth.get("/openstack/projects");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async updateUserProjects(data) {
    try {
      return baseUrlWithAuth.put(`/openstack/projects/${data.projectId}`, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createProject(data) {
    try {
      return baseUrlWithAuth.post("openstack/projects/create-project", data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async switchProject(projectId) {
    try {
      return baseUrlWithAuth.get(`openstack/switch-project/${projectId}`);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async listImages() {
    try {
      return baseUrlWithAuth.get("/openstack/images");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createKeypair(data, projectId) {
    try {
      return baseUrlWithAuth.post(
        `openstack/${projectId}/keypair-create`,
        data
      );
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async listKeypairs(projectId) {
    try {
      return baseUrlWithAuth.get(`/openstack/${projectId}/keypairs`);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createServer(data, projectId) {
    try {
      return baseUrlWithAuth.post(`openstack/${projectId}/server-create`, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async listServers(projectId) {
    try {
      return baseUrlWithAuth.get(`/openstack/${projectId}/servers`);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createSnapshot(data) {
    try {
      return baseUrlWithAuth.post(
        `/openstack/${data.projectId}/snapshots`,
        data
      );
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createVirtualAccount(data) {
    try {
      return virtualAccountBaseUrl.post(`/create_account`, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createSessionVirtualAccount(data) {
    try {
      return baseUrlWithAuth.post(`openstack/credit`, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async getVirtualAccount(data) {
    try {
      return baseUrlWithAuth.get(`openstack/credit`, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }
  static async createContactSupport(data) {
    try {
      return baseUrlWithAuth.post(`openstack/contact-us`, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createSecurityGroup(data, projectId) {
    try {
      return baseUrlWithAuth.post(
        `openstack/${projectId}/create-security-group`,
        data
      );
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createSecurityGroupRule(data, projectId, securityGroupId) {
    try {
      return baseUrlWithAuth.post(
        `openstack/${projectId}/${securityGroupId}/create-security-group-rule`,
        data
      );
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async listSecurityGroups(projectId) {
    try {
      return baseUrlWithAuth.get(`openstack/${projectId}/security-groups`);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async deleteSecurityGroup(projectId, securityGroupId) {
    try {
      const url = `openstack/${projectId}/security-groups/${securityGroupId}`;
      return await baseUrlWithAuth.delete(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async getSingleSecurityGroups(projectId, securityGroupId) {
    try {
      const url = `openstack/${projectId}/security-groups/${securityGroupId}`;
      return await baseUrlWithAuth.get(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async deleteSecurityGroupRule(projectId, securityGroupRuleId) {
    try {
      const url = `openstack/${projectId}/security-group-rules/${securityGroupRuleId}`;
      return await baseUrlWithAuth.delete(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createAttachSecurityGroupInstance(data, projectId) {
    try {
      const url = `openstack/${projectId}/add-security-group`;
      return await baseUrlWithAuth.post(url, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async getResourceStatus(serverId) {
    try {
      const url = `openstack/resources/${serverId}`;
      return await baseUrlWithAuth.get(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async deleteProject(projectId) {
    try {
      const url = `openstack/projects/${projectId}`;
      return await baseUrlWithAuth.delete(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async deleteServer(projectId, serverId) {
    try {
      const url = `openstack/${projectId}/servers/${serverId}`;
      return await baseUrlWithAuth.delete(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async stopServer(projectId, serverId) {
    try {
      const url = `openstack/${projectId}/servers/${serverId}/stop`;
      return await baseUrlWithAuth.get(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async restartServer(projectId, serverId) {
    try {
      const url = `openstack/${projectId}/servers/${serverId}/start`;
      return await baseUrlWithAuth.get(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async volumeList(projectId) {
    try {
      const url = `openstack/${projectId}/volumes`;
      return await baseUrlWithAuth.get(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async passwordResetLink(data) {
    try {
      return baseUrlNoAuth.post(`users/password-reset-email`, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }
  static async setNewPassword(data) {
    try {
      return baseUrlNoAuth.post(`/users/set-new-password`, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createVolume(data, projectId) {
    try {
      const url = `openstack/${projectId}/volume-create`;
      return await baseUrlWithAuth.post(url, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }
  //This is where billing module start from --dynamite

  static async userResourceSubscription() {
    try {
      return baseUrlWithAuth.get("/openstack/billing/subscriptions");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async creditHistory() {
    try {
      return baseUrlWithAuth.get("/openstack/billing/credit-history");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async paymentHistory() {
    try {
      return baseUrlWithAuth.get("/openstack/billing/payment-history");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async getInvoices() {
    try {
      return baseUrlWithAuth.get("/openstack/billing/get_invoice");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async attachInstance(data, projectId, serverId) {
    try {
      const url = `openstack/${projectId}/servers/${serverId}/attach-volume`;
      return await baseUrlWithAuth.post(url, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async detachInstance(projectId, serverId, volumeId) {
    try {
      const url = `openstack/${projectId}/volumes/${serverId}/${volumeId}/detach-from-server`;
      return await baseUrlWithAuth.post(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async generatePaymentLink(data) {
    try {
      const url = `openstack/flutterwave/payment_link`;
      return await baseUrlWithAuth.post(url, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async deleteVolumeFromInstance(projectId, volumeId) {
    try {
      const url = `openstack/volumes/${projectId}/${volumeId}?cascade=1`;
      return await baseUrlWithAuth.delete(url);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }
}
